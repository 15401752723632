export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: "reportCenter",
  /**
   * @description 报告中心请求基础路径
   */
  baseUrl: process.env.NODE_ENV === 'development' ? '/apis' : process.env.VUE_APP_BASE_URL,
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: "reportCenter",
  /**
   * @description 不需要登录的页面列表
   */
  whitePage: ['error_401','error_404'],
};
