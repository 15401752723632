import config from "@/config";
const { cookieExpires } = config;

export const TOKEN_KEY = "tokenBack";

export const setToken = token => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify({ "tokenBack": token, startTime: new Date().getTime() }));
};

export const getToken = () => {
  const tokenData = JSON.parse(localStorage.getItem(TOKEN_KEY));
  let date = new Date().getTime();
  // 如果大于就是过期了，如果小于或等于就还没过期，设置4小时
  if(!tokenData || (date - Number(tokenData.startTime) > 14400000)){
    localStorage.removeItem('tokenBack');
    return false;
  }else{
    return tokenData.tokenBack
  };
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const localSave = (key, value) => {
  localStorage.setItem(key, value);
};

export const localRead = key => {
  return localStorage.getItem(key) || "";
};