import layout from "@/layout";
const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/loading",
    meta: {
      showMenus: false,
    },
  },
  {
    path: "/loading",
    component: () => import("@/views/loading"),
    hidden: true
  },
  {
    path: "/download",
    name: 'download',
    component: layout,
    redirect: "/download/downloadList",
    meta: {
      title: "下载中心"
    },
    children: [
      {
        path: "downloadList/:deliverId/:projectId",
        name: "downloadList",
        meta: {
          title: '下载中心列表',
          navTitle: '下载中心'
        },
        component: () => import("@/views/download/downloadList"),
      },
    ]
  },
  {
    path: "/deliver",
    name: 'deliver',
    component: layout,
    redirect: "/deliver/deliverList",
    meta: {
      title: "报告中心"
    },
    children: [
      {
        path: "deliverList",
        name: "deliverList",
        meta: {
          title: '报告中心列表',
          navTitle: '报告中心'
        },
        component: () => import("@/views/deliver/deliverList"),
      },
    ]
  },
  {
    path: "/project",
    name: 'project',
    component: layout,
    redirect: "/project/projectList",
    meta: {
      title: "项目中心"
    },
    children: [
      {
        path: "projectList",
        name: "projectList",
        meta: {
          title: '项目中心列表',
          navTitle: '项目中心'
        },
        component: () => import("@/views/project/projectList"),
      },
    ]
  }
]
export default routes;