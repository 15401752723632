/**
 * 文件描述: 用户行为日志
 * @author 阿苏
 * @dTime  2023/04/14
 * @see    
 */


import feach from "@/utils/request";
/**
 * @description 埋点接口
 */
const trackApi = data => {
  return feach.request({
    url: '/systemLog/saveSystemLog',
    method: "post",
    data
  });
};
// 获取用户信息
const getInfo = params => {
  return feach.request({
    url: "/account/getInfo",
    method: "get",
  });
};
// 自定义指令
import Vue from 'vue';
Vue.directive('track', {
  inserted(el, binding) {
    // 在插入元素时执行埋点逻辑
    const {
      name,
      value
    } = binding;
    console.log(`Track: ${name} - ${value}`);
  }
});

const track = async (module, fn, logDescription) => {
  let {
    code,
    body
  } = await getInfo()
  console.log(body);
  let info = `客户${body.companyName},人员${body.personnelName},`
  trackApi({
    module,
    function: fn,
    logDescription: info + logDescription
  })
}
Vue.prototype['$track'] = track