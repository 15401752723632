<template>
  <div id="app" class="flex jc-c">
    <router-view />
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
      
    };
  },
}
</script>
<style lang="scss">

</style>
