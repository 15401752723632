import axios from "axios";
import config from "@/config";
import {
  Message
} from 'view-design';
const baseUrl = config.baseUrl;
axios.defaults.withCredentials = true; //让ajax携带cookie
class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  //默认配置公共参数
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {},
    };
    return config;
  }
  //根据code 执行不通操作
  codeState(data) {
    //当返回的是
    if (typeof data === 'string') return {
      code: 200,
      msg: '成功',
      body: data
    };
    let {
      code,
      message
    } = data;
    if (parseInt(code) === 200) {
      data.msg = "成功";
      return data;
    } else if (code === 223) {
      //登录失效，需要重新登录
      if (document.getElementsByClassName('ivu-message').length <= 0) {
        Message.error({
          content: '该账号没有此权限',
          duration: 8,
          closable: true,
          onClose: () => {
            //跳转到星图云登录页面
            window.location.href = (process.env.VUE_APP_JUMP_URL + "/#/login");
          }
        });
      }
    } else if (code === 217) {
      //登录失效，需要重新登录
      if (document.getElementsByClassName('ivu-message').length <= 0) {
        Message.error({
          content: '登录已失效，需要重新登录',
          duration: 8,
          closable: true,
          onClose: () => {
            //跳转到星图云登录页面
            window.location.href = (process.env.VUE_APP_JUMP_URL + "/#/login");
          }
        });
      }
    } else if (code === 505) {
      if (document.getElementsByClassName('ivu-message').length <= 0) {
        Message.error({
          content: message,
          duration: 8,
          closable: true,
          onClose: () => {}
        });
      }
    } else if (code === 500) {
      return data;
    } else {
      return data;
    }
  }

  // 请求拦截
  interceptors(instance, url) {
    instance.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        return Proimse.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        const {
          data,
          status
        } = res;
        if (status == 200) return Promise.resolve(this.codeState(data)); //状态操作
        else return Promise.reject(data);
      },
      error => {
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: {
              statusText,
              status
            },
            config
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: {
              responseURL: config.url
            }
          };
        }
        return Promise.reject(error);
      }
    );
  }

  //抛出调用方法
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

const feach = new HttpRequest(baseUrl);
export default feach;