import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import user from "./module/user";

Vue.use(Vuex);

const ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "reportCenter"
});

export default new Vuex.Store({
  state: {
    menusId:{}
  },
  mutations: {
    setMenusId(state,resa){
      sessionStorage.setItem('role',JSON.stringify(resa))
      state.menusId = resa
    }
  },
  actions: {},
  modules: {
    user,
  },
  plugins: [
    createPersistedState({
      key: "reportCenter",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});
