<template>
  <header class="head">
    <div class="main">
      <img src="@/assets/syntunlogo.png" alt="" class="headLogo" />
      <!-- <div class="mainTitle">交付中心</div> -->
      <div :class="activeNav == '报告中心' ? 'mainNav mainNavActive' : 'mainNav'" @click="goOut('/deliver/deliverList','交付中心')" v-if="activeNav=='报告中心' || activeNav=='项目中心'">报告中心</div>
      <div :class="activeNav == '项目中心' ? 'mainNav mainNavActive' : 'mainNav'" @click="goOut('/project/projectList','项目中心')" v-if="activeNav=='报告中心' || activeNav=='项目中心'">项目中心</div>
    </div>
    <div class="user-info">
      <div class="backSyntun" @click="goSyntun()">星图云</div>
      <!-- <Dropdown @on-click='selFn'>
        <a href="javascript:void(0)">
          <Avatar icon="ios-person" size="large" />
        </a>
        <template #list>
          <DropdownMenu>
            <DropdownItem name='logOut' @click="logOut">退出登录</DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown> -->
    </div>
  </header>
</template>
<script>
export default {
  data () {
    return {
      activeNav: "",
    };
  },
  watch: {
    $route: {
      handler (newName, oldName) {
        this.activeNav = newName.meta.navTitle;
      },
      immediate: true,
    },
  },
  mounted(){},
  methods: {
    // 跳转页面
    goOut(path,activeName){
      // 判断要跳转的页面是否是当前页面
      if (this.$route.path == path) return;
      this.$router.push(path);
    },
    selFn (name) {
      if (name == "logOut") {
        this.logOut();
      }
    },
    /**
     * @description 退出登录
     * @param
     */
    logOut () {},
    // 返回星图云
    goSyntun(){
      window.location.href = process.env.VUE_APP_JUMP_URL;
    },
    //跳转交付中心
    goCenter(){
      this.$router.push('/deliver/deliverList');
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 3px 0px 30px 2px rgba(13,72,177,0.12);
  .main{
    display: flex;
    align-items: center;
    .headLogo{
      width: 180px;
      height: 50px;
      margin-right: 80px;
    }
    .mainTitle{
      font-size: 24px;
      font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
      font-weight: bold;
      color: #333333;
      margin: 0 40px 0 10px;
    }
    .mainNav{
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      margin-right: 30px;
    }
    .mainNavActive{
      color: #328CFF;
      font-weight: bold;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .user-name {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      margin-left: 10px;
    }
    .backSyntun{
      cursor: pointer;
    }
  }
}
</style>