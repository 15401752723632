import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import VueCookies from 'vue-cookies'
import {
  DatePicker
} from 'element-ui';
import "@/utils/directive";
Vue.use(VueCookies)

Vue.config.productionTip = false;
Vue.use(ViewUI);
Vue.use(DatePicker);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");