<template>
  <Layout style="height: 100vh;">
    <!-- 头部 -->
    <Header class="layout-header-bar"></Header>
    <!-- 内容 -->
    <Content class="content">
      <router-view></router-view>
    </Content>
  </Layout>
</template>

<script>
// 组件
import Header from "./components/Header";
export default {
  data () {
    return {
      
    };
  },
  components: {
    Header,
  },
  mounted () {
    
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
  .content{
    height: calc(100vh - 70px);
    background: url('~@/assets/bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    //设置第二张背景图
    &:after{
      content: '';
      position: absolute;
      top: 260px;
      right: 100px;
      width: 388px;
      height: 388px;
      background: url('~@/assets/bgtwo.png') no-repeat;
      background-size: 100% 100%;
    }
  }
</style>
